<template>
    <section class="login h-full">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8 flex items-center justify-center flex-col">
                <!-- <brand-logo class="mb-6" /> -->
                <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <component :is="componentMapper[currentConfig].component" :formData="formData"  @on-change="onChange($event)" :errors="$v" @on-submit="onSubmit($event)" :loadingStates="loadingStates" :loginStates="loginStates" :isNewClient="isNewClient" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//comp
import BrandLogo from "@/assets/icons/brand-login.svg";
import VueCookies from "vue-cookies";

const LoginEmail = () => import("./components/login-email");
const LoginOTP = () => import("./components/login-otp");
const LoginClient = () => import("./components/login-client");
const ForgotPassword = () => import("./components/forgot-password");
const ResetPassword = () => import("./components/reset-password");

//libs
import { required, email, sameAs, helpers } from "vuelidate/lib/validators";
import axios from "@/axios";
const passwordReg = helpers.regex("alpha", /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{12,}$/);

export default {
    name: "LOGIN",
    components: {
        BrandLogo,
        LoginEmail,
        LoginOTP,
        LoginClient,
        ResetPassword,
    },
    validations: {
        formData: {
            email: {
                required,
                email,
            },
            otp: {
                required,
            },
            password: {
                required,
                passwordReg,
            },
            confirmPassword: { required, sameAsPassword: sameAs("password") },
        },
        validationGroup: ["formData.password", "formData.confirmPassword"],
    },
    computed: {
    },
    data() {
        return {
            componentMapper: {
                loginEmail: { component: LoginEmail },
                loginOTP: { component: LoginOTP },
                loginClient: { component: LoginClient },
                forgotPassword: { component: ForgotPassword },
                resetPassword: { component: ResetPassword },
            },
            formData: {
                email: "",
                otp: "",
                clients: null,
                password: "",
                confirmPassword: "",
                session_id: "",
            },
            currentConfig: "loginEmail",
            loadingStates: {
                otp: false,
                otpVerify: false,
                otpResend: false,
                resetPass: false,
                otpForgot: false,
                passLogin: false,
            },
            loginStates: {
                otpResend: false,
                counterValue: 30,
                clients: [],
            },
            isNewClient: false,
        };
    },
    methods: {
        onChange({ value, type }) {
            this.formData[type] = value;
            // this.$v.formData[type].$touch();
        },
        async onSubmit({ component }) {
            console.log("component :>> ", component);
            switch (component) {
                case "loginOTP":
                    await this.onSendOTP(component, "otp");
                    break;
                case "loginClient":
                    await this.onVerifyOTP(component);
                    break;
                case "loginEmail":
                    this.resetFormData();
                    this.currentConfig = component;
                    break;
                case "resendOTP":
                    await this.onSendOTP(null, "otpResend");
                    this.startCounter();
                    break;
                case "forgotPassword":
                    this.formData.otp = "";
                    this.formData.password = "";
                    await this.onReqResetPass(component, "otpForgot");
                    break;
                case "resetState":
                    // this.formData.otp = "";
                    await this.onResetPassword("loginClient");
                    break;
                case "case-dashboard":
                    // this.currentConfig =
                    await this.onPasswordLogin();
                    break;

                default:
                    this.currentConfig = component;
                    break;
            }
        },
        resetFormData() {
            this.formData = {
                email: "",
                otp: "",
                clients: null,
                password: "",
                confirmPassword: "",
                session_id: "",
            };
        },
        async onSendOTP(component, type) {
            this.loadingStates[type] = true;
            try {
                let {data} = await axios.post(`/client-auth/send-otp`, {
                    email: this.formData.email,
                });
                this.formData = {...this.formData, masked_no:data?.masked_no || null}
                console.log(data, "formdata")
                this.currentConfig = component ?? this.currentConfig;
            } catch (error) {
                // this.$toast.error("Failed to Send OTP, Please Try Again");
                this.$toast.error("Failed to Send One-time Password, Please Try Again", {
                    hideProgressBar: true,
                });
                console.log(error, "[+] error while sending the OTP");
            }
            this.loadingStates[type] = false;
        },
        async onVerifyOTP(component) {
            this.loadingStates.otpVerify = true;
            try {
                const { email, otp } = this.formData;
                const { data } = await axios.post(`/client-auth/validate-otp`, {
                    email,
                    pin: otp,
                });
                console.log("data :>> ", data);
                this.loginStates.clients = data?.clients ?? [];
                if (data?.clients?.length == 1) {
                    this.formData.clients = data.clients[0];
                }
                this.currentConfig = component;
            } catch (error) {
                this.$toast.error("Wrong One-time Password, Please Try Again", {
                    hideProgressBar: true,
                });
                console.log(error, "[+] error while validating the OTP");
            }
            this.loadingStates.otpVerify = false;
        },
        startCounter() {
            this.loginStates.otpResend = true;
            this.loginStates.counterValue = 30;
            const delay = 1000;
            const timer = setInterval(() => {
                this.loginStates.counterValue -= 1;
                if (this.loginStates.counterValue === 0) {
                    clearInterval(timer);
                    this.loginStates.otpResend = false;
                }
            }, delay);
        },
        async onResetPassword(component) {
            this.loadingStates.resetPass = true;
            try {
                const { email, otp, clients, password, session_id } = this.formData;
                if (this.isNewClient) {
                    console.log("this.formData :>> ", this.formData);
                    const { data } = await axios.post(`/client-auth/submit-new-password`, {
                        new_password: password,
                        session: session_id,
                        user_id: clients.user_id,
                        client_id: clients.id,
                    });
                    this.loginUser(data);
                } else {
                    await axios.post(`/client-auth/submit-reset-password`, {
                        pin: otp,
                        new_password: password,
                        user_id: clients.user_id,
                        client_id: clients.id,
                        email,
                    });
                    this.currentConfig = component;
                }
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to Reset Password, Please Try Again", {
                    hideProgressBar: true,
                });
                console.log(error, "[+] error while resetting the password");
            }
            this.loadingStates.resetPass = false;
        },
        async onReqResetPass(component, type) {
            this.loadingStates[type] = true;
            try {
                await axios.post(`/client-auth/request-reset-password`, {
                    client_id: this.formData.clients.id,
                    user_id: this.formData.clients.user_id,
                });
                this.currentConfig = component;
            } catch (error) {
                this.$toast.error("Failed to Send One-time Password, Please Try Again", {
                    hideProgressBar: true,
                });
                console.log(error, "[+] error while requesting the OTP");
            }
            this.loadingStates[type] = true;
        },
        async onPasswordLogin() {
            this.loadingStates.passLogin = true;
            try {
                const { email, clients, password } = this.formData;
                const { data } = await axios.post(`/client-auth/password-login`, {
                    email,
                    client_id: clients.id,
                    password,
                });
                if (data.response_type === "new_password_required") {
                    this.formData.password = "";
                    this.formData.session_id = data.session;
                    this.currentConfig = "resetPassword";
                    this.isNewClient = true;
                } else {
                    this.isNewClient = false;
                    this.loginUser(data);
                }
            } catch (error) {
                this.$toast.error("Incorrect password, Please Try Again", {
                    hideProgressBar: true,
                });
                console.log(error, "[+] error while login");
            }
            this.loadingStates.passLogin = false;
        },
        loginUser(userInfo = {}) {
            let user = {};
            let { user_id, user_name } = userInfo;
            user = { user_id, email, user_name };
            this.$store.dispatch("setLoginSession", { token: userInfo?.access_token, refresh_token: userInfo?.refresh_token, userLoggedIn: true, user });
            console.log("!!!YOLO!!!");
            if(VueCookies.get("next-url") && VueCookies.get("next-url").includes("#fileManager") )
                this.$router
                    .push(VueCookies.get("next-url"))
            else
                this.$router
                    .push({
                        name: "dashboard.view",
                    })
                    .catch((e) => e);
            },
    },
};
</script>

<style lang="scss" scoped>
.login {
    background: #4d545c;
}

::v-deep {
    .otp:disabled {
        background-color: #a8a8a8 !important;
        cursor: none !important;
    }
    .loader {
        height: 1.5rem;
        width: 1.5rem;
        border-color: inherit;
        border-width: 2px !important;
    }
    .multiselect__tags {
        font-weight: 500 !important;
    }
    .dropdown {
        border-radius: 0.5rem !important;
    }
}
</style>
